<template>
  <div class="manageBox">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <div class="baseInfo">
        <div class="public-title">基本信息</div>
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名：" prop="name">
              <el-input class="w300px" v-model="ruleForm.userName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话：" prop="name">
              <el-input class="w300px" v-model="ruleForm.phone" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="身份证号：" prop="name">
              <el-input class="w300px" v-model="ruleForm.cardId" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期：" prop="name">
              <el-date-picker v-model="ruleForm.birthday" type="date" disabled class="w300px" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="性别：" prop="name">
              <el-select class="w300px" v-model="ruleForm.sex" placeholder="请选择" disabled>
                <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属区划：" prop="name">
              <el-select class="w300px" v-model="ruleForm.orgId" disabled placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="户籍地址：" prop="name">
              <el-input class="w300px" v-model="ruleForm.residenceAddress" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="家庭地址：" prop="name">
              <el-input class="w300px" v-model="ruleForm.homeAddress" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="婚姻状况：" prop="name">
              <el-select class="w300px" v-model="ruleForm.maritalStatus" placeholder="请选择" disabled>
                <el-option v-for="item in maritalList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="城乡类别：" prop="name">
              <el-select class="w300px" v-model="ruleForm.occupancyCategory" placeholder="请选择" disabled>
                <el-option v-for="item in occupancyCategoryList" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="home-care">
        <div class="public-title">居家养老信息</div>
        <el-row>
          <el-col :span="8">
            <el-form-item label="服务对象类别：" prop="name">
              <el-input class="w300px" v-model="ruleForm.objectName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="服务内容：">
              <el-checkbox-group v-model="ruleForm.objectServiceContent">
                <el-checkbox label="1" disabled>助老服务</el-checkbox>
                <el-checkbox label="2" disabled>信息服务</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="生活自理能力：" prop="name">
              <el-select class="w300px" v-model="ruleForm.livingAbility" placeholder="请选择" disabled>
                <el-option v-for="item in livingAbilityList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="附件：" prop="name">
              <div class="img-wrap">
                <img :src="item.url" alt="" v-for="item in ruleForm.enclosureList" :key="item.id"
                  @click="seePicture(item)">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="是否委托代理：">
              <el-radio-group v-model="ruleForm.clientStatus">
                <el-radio :label="0" disabled>是</el-radio>
                <el-radio :label="1" disabled>否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="agent" v-if="ruleForm.clientStatus===0">
        <div class="public-title">委托代理人信息</div>
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名：" prop="name">
              <el-input class="w300px" v-model="ruleForm.clientName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号码：" prop="name">
              <el-input class="w300px" v-model="ruleForm.clientCardId" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="出生日期：" prop="name">
              <el-date-picker v-model="ruleForm.clientBirthday" type="date" class="w300px" disabled placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别：" prop="name">
              <el-select class="w300px" v-model="ruleForm.clientSex" placeholder="请选择" disabled>
                <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value+''">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="与申请人关系：" prop="name">
              <el-select class="w300px" v-model="ruleForm.clientRelation" placeholder="请选择" disabled>
                <el-option v-for="item in relationList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话：" prop="name">
              <el-input class="w300px" v-model="ruleForm.clientPhone" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="examine">
        <div class="public-title">审核信息</div>
        <el-steps align-center>
          <el-step
            :status="index=== 0 ? 'success' : item.handTime && item.auditState == 2? 'success': item.handTime && item.auditState == 3? 'error': 'wait'"
            v-for="(item, index) in ruleForm.handList" :key="index">

            <div slot="title">
              <div class="topitem">
                {{index=== 0 ? '提交申请' : item.handTime && item.auditState == 2? '审核通过': item.handTime && item.auditState == 3? '审核不通过': '待审核'}}
              </div>
              <div>{{item.name}}</div>
            </div>
            <div slot="description" class="description">
              <div>
                {{item.handTime}}
              </div>
              <div v-if="item.remarks">
                {{index=== 0 ? '提交申请备注：' : item.handTime && item.auditState == 2? '审核通过原因：': item.handTime && item.auditState == 3? '审核不通过原因:': '待审核备注：'}}{{item.remarks}}
              </div>
            </div>
          </el-step>
        </el-steps>
      </div>
      <div class="bottom-buttom">
        <el-row>
          <el-col :span="12">
            <el-form-item>
              <el-button @click="$router.push('/homeCasedCareServices/applicationList')">返 回</el-button>
              <el-button type="primary" @click="examineDlilogVisible=true" v-if="ruleForm.operate&&type===2">审 核
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <!-- <adressDilog v-if="visible" :visible.sync="visible" @postData="getAdressData"></adressDilog>
    <editApplicationSubmit v-if="editApplicationSubmitVisible"
      :editApplicationSubmitVisible.sync="editApplicationSubmitVisible" /> -->
    <examineDlilog v-if="examineDlilogVisible" :id="ruleForm.id" :type="ruleForm.type"
      :examineDlilogVisible.sync="examineDlilogVisible" />
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
// import upload from "./components/upload";
import { details } from '@/api/pensionService'
import adressDilog from './components/adressDilog'
import editApplicationSubmit from '../components/editApplicationSubmit'
import examineDlilog from './components/examineDlilog'
export default {
  name: 'applicationEdit',
  components: {
    // upload,
    adressDilog,
    editApplicationSubmit,
    examineDlilog
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      occupancyCategoryList: [
        { label: '城镇', value: 0 },
        { label: '农村', value: 1 }
      ],
      maritalList: [
        { label: '已婚', value: 1 },
        { label: '未婚', value: 2 },
        { label: '离异', value: 3 },
        { label: '丧偶', value: 4 }
      ],
      livingAbilityList: [
        { label: '自理', value: 1 },
        { label: '半失能', value: 2 },
        { label: '全失能', value: 3 }
      ],
      relationList: [
        { label: '父子(女)', value: 1 },
        { label: '母子(女)', value: 2 },
        { label: '夫妻', value: 3 },
        { label: '兄弟姐妹', value: 4 },
        { label: '祖孙', value: 5 }
      ],
      sexList: [
        { label: '女', value: 0 },
        { label: '男', value: 1 }
      ],
      examineDlilogVisible: false,
      editApplicationSubmitVisible: false,
      visible: false, //地图弹框
      ruleForm: {},
      value: '',
      options: [],
      rules: {},
      type: 1
    }
  },
  mounted() {
    this.type = Number(this.$route.query.type)
    this.getDetails()
  },
  methods: {
    seePicture(item) {
      this.dialogImageUrl = item.url
      this.dialogVisible = true
    },
    auditStateForamt(id) {
      let text = '--'
      switch (id) {
        case 0:
          text = '暂存'
          break
        case 1:
          text = '待审核'
          break
        case 2:
          text = '审核通过'
          break
        case 3:
          text = '审核不通过'
          break
        default:
          break
      }
      return text
    },
    onSubmit() {},
    /*获取弹框的地址信息*/
    getAdressData(data) {
      console.log(data, '@@@')
    },
    async getDetails() {
      const res = await details({ id: this.$route.query.id })
      res.data.objectServiceContent = res.data.objectServiceContent.split(',')
      this.ruleForm = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.manageBox {
  // margin: 20px;
  background: #fff;
  padding: 20px;
  min-height: calc(100vh - 130px);
  .examine {
    width: 50%;
  }
  .public-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .home-care {
    margin-top: 30px;
  }
  .w300px {
    width: 300px;
  }
  .bottom-buttom {
    text-align: center;
    margin-top: 30px;
  }
}
.img-wrap {
  img {
    width: 120px;
    height: 120px;
    margin-right: 10px;
  }
}
</style>
